// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-js": () => import("/opt/build/repo/src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-blog-jsx": () => import("/opt/build/repo/src/pages/about-us/blog.jsx" /* webpackChunkName: "component---src-pages-about-us-blog-jsx" */),
  "component---src-pages-about-us-contact-us-jsx": () => import("/opt/build/repo/src/pages/about-us/contact-us.jsx" /* webpackChunkName: "component---src-pages-about-us-contact-us-jsx" */),
  "component---src-pages-about-us-meetups-jsx": () => import("/opt/build/repo/src/pages/about-us/meetups.jsx" /* webpackChunkName: "component---src-pages-about-us-meetups-jsx" */),
  "component---src-pages-about-us-roadmap-jsx": () => import("/opt/build/repo/src/pages/about-us/roadmap.jsx" /* webpackChunkName: "component---src-pages-about-us-roadmap-jsx" */),
  "component---src-pages-about-us-team-jsx": () => import("/opt/build/repo/src/pages/about-us/team.jsx" /* webpackChunkName: "component---src-pages-about-us-team-jsx" */),
  "component---src-pages-about-us-videos-jsx": () => import("/opt/build/repo/src/pages/about-us/videos.jsx" /* webpackChunkName: "component---src-pages-about-us-videos-jsx" */),
  "component---src-pages-blockchain-infrastructure-cybermiles-app-jsx": () => import("/opt/build/repo/src/pages/blockchain-infrastructure/cybermiles-app.jsx" /* webpackChunkName: "component---src-pages-blockchain-infrastructure-cybermiles-app-jsx" */),
  "component---src-pages-blockchain-infrastructure-venus-jsx": () => import("/opt/build/repo/src/pages/blockchain-infrastructure/venus.jsx" /* webpackChunkName: "component---src-pages-blockchain-infrastructure-venus-jsx" */),
  "component---src-pages-cmt-cmt-cube-jsx": () => import("/opt/build/repo/src/pages/cmt/cmt-cube.jsx" /* webpackChunkName: "component---src-pages-cmt-cmt-cube-jsx" */),
  "component---src-pages-cmt-overview-jsx": () => import("/opt/build/repo/src/pages/cmt/overview.jsx" /* webpackChunkName: "component---src-pages-cmt-overview-jsx" */),
  "component---src-pages-cmt-validator-jsx": () => import("/opt/build/repo/src/pages/cmt/validator.jsx" /* webpackChunkName: "component---src-pages-cmt-validator-jsx" */),
  "component---src-pages-cmt-whitepaper-jsx": () => import("/opt/build/repo/src/pages/cmt/whitepaper.jsx" /* webpackChunkName: "component---src-pages-cmt-whitepaper-jsx" */),
  "component---src-pages-developer-portal-developer-hub-jsx": () => import("/opt/build/repo/src/pages/developer-portal/developer-hub.jsx" /* webpackChunkName: "component---src-pages-developer-portal-developer-hub-jsx" */),
  "component---src-pages-developer-portal-documentation-technical-benchmark-jsx": () => import("/opt/build/repo/src/pages/developer-portal/documentation/technical-benchmark.jsx" /* webpackChunkName: "component---src-pages-developer-portal-documentation-technical-benchmark-jsx" */),
  "component---src-pages-ecommerce-solutions-finance-payment-gateway-jsx": () => import("/opt/build/repo/src/pages/ecommerce-solutions/finance/payment-gateway.jsx" /* webpackChunkName: "component---src-pages-ecommerce-solutions-finance-payment-gateway-jsx" */),
  "component---src-pages-ecommerce-solutions-finance-security-token-jsx": () => import("/opt/build/repo/src/pages/ecommerce-solutions/finance/security-token.jsx" /* webpackChunkName: "component---src-pages-ecommerce-solutions-finance-security-token-jsx" */),
  "component---src-pages-ecommerce-solutions-finance-stable-coin-jsx": () => import("/opt/build/repo/src/pages/ecommerce-solutions/finance/stable-coin.jsx" /* webpackChunkName: "component---src-pages-ecommerce-solutions-finance-stable-coin-jsx" */),
  "component---src-pages-ecommerce-solutions-marketing-marketing-referral-program-jsx": () => import("/opt/build/repo/src/pages/ecommerce-solutions/marketing/marketing-referral-program.jsx" /* webpackChunkName: "component---src-pages-ecommerce-solutions-marketing-marketing-referral-program-jsx" */),
  "component---src-pages-ecommerce-solutions-marketing-user-engagement-solutions-jsx": () => import("/opt/build/repo/src/pages/ecommerce-solutions/marketing/user-engagement-solutions.jsx" /* webpackChunkName: "component---src-pages-ecommerce-solutions-marketing-user-engagement-solutions-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-jsx": () => import("/opt/build/repo/src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-terms-jsx": () => import("/opt/build/repo/src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

